.Header {
  background-color: black;
  color: white;
  padding: 20px;
}
.Logo {
  width: 100%;
}

.Header-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 85px;
}
.Link {
  display: flex;
  color: white;
  text-decoration: none;
}
.Link img {
  padding-right: 5px;
  transition: transform 250ms ease-out;
}
.Link:hover img {
  transform: translateX(-5px);
}
.Language {
  color: rgba(255,255,255,0.5);
}
.Language button {
  background: none;
  color: rgba(255,255,255,0.5);
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.Active {
  color: rgba(255,255,255,1) !important;
}
