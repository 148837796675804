.Info {
  max-width: 1000px;
  margin: 0 auto;
  color: black;
  text-align: left;
  padding: 10vh 10vw;
  line-height: 22px;
}
.Info h4 {
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 20px;
}
.Info a {
  color: black
}

.Partners-logo {
  padding-top: 20px;
  display: flex;
}
.Partners-logo img {
  height: 76px;
  width: fit-content;
}
