.Progress {

  background-color: rgba(0, 0, 0, 0.03);
  padding: 10vh 10vw;
  text-align: left;
  line-height: 22px;
}
.Inner-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
.Progress h4 {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 35px;
}
.Content-block {
  padding-bottom: 70px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.Content-block small {
  color: rgba(0,0,0,0.5)
}

.Content-block img {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
}
.Content-block a {
  color: black;
}
